<template lang="pug">
.report-controls
  //- HIDDEN until zorbas issue with filtering min/max with groupseries is resolved
  //- span.hidden-xs.hidden-sm(v-if="controls.trend_direction")
  //-   trend-direction-selector( :value="sortDirection", @input="updateSortDirection")
  span.hidden-xs.hidden-sm(v-if="controls.trend_chart_variant")
    trend-chart-variant-selector( :value="chartVariant", @input="updateChartVariant")
  span.hidden-xs.hidden-sm(v-if="controls.trend_sort")
    trendSortSelector(:value="sortKey" @input="updateSortKey")
  span.hidden-xs.hidden-sm(v-if="controls.data_labels")
    dataLabelsSelector(:value="chartOptions.data_labels" @input="updateDataLabels")
  span.hidden-xs.hidden-sm(v-if="controls.proportions")
    proportionsSelector(:value="filterConfiguration.raw_filters.proportions" @input="updateProportions")
  span.hidden-xs.hidden-sm(v-if="controls.orientation") 
    chartTypeSelector(:value="chartOptions.inverted ? 'bar' : 'column'" @input="updateChartOrientation")
  span.hidden-xs.hidden-sm(v-if="controls.round_values")
    roundValuesSelector(:value="filterConfiguration.raw_filters.round_values" @input="updateRoundValues")
  span.hidden-xs.hidden-sm(v-if="controls.delta_values")
    tableColumnSelector(:config="tableColumns" @change="updateTableColumns")
  span.hidden-xs.hidden-sm(v-if="controls.cross_tab_table_config")
    crossTabSettingsModal(:table-config="tableConfig" :filter-configuration="mergedFilterConfiguration" @update="updateTableConfig")
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import trendSortSelector from "../components/trend-sort-selector.vue";
import dataLabelsSelector from "../components/data-labels-selector.vue";
import proportionsSelector from "../components/proportions-selector.vue";
import roundValuesSelector from "../components/round-values-selector.vue";
import chartTypeSelector from "../components/chart-type-selector.vue";
import tableColumnSelector, { TableColumnSelectorConfig } from "../components/table-column-selector.vue";
import crossTabSettingsModal from "../components/cross-tab-settings-modal.vue";
import FilterConfiguration from "../model/filter-configuration";
import ChartOptions from "../model/chart-options";
import TableConfig from "../model/table-config";
import { configToActive, filterToFlyover, flatFilterConfiguration } from "../lib/filter-util";
import _ from "lodash";
import TrendDirectionSelector from "@/components/trend-direction-selector.vue";
import TrendChartVariantSelector from "@/components/trend-chart-variant-selector.vue";

@Component({
  components: {
    trendSortSelector,
    dataLabelsSelector,
    proportionsSelector,
    roundValuesSelector,
    chartTypeSelector,
    tableColumnSelector,
    crossTabSettingsModal,
    TrendDirectionSelector,
    TrendChartVariantSelector,
  },
})
export default class ReportControls extends Vue {
  @Prop()
  controls: Record<string, boolean>;

  @Prop()
  filterConfiguration: FilterConfiguration;

  @Prop()
  chartOptions: ChartOptions;

  @Prop()
  tableConfig: TableConfig;

  get flatFilterConfiguration() {
    return this.filterConfiguration && flatFilterConfiguration(this.filterConfiguration);
  }

  get filters() {
    return this.flatFilterConfiguration && { ...configToActive(this.flatFilterConfiguration) };
  }

  get mergedFilterConfiguration() {
    return {
      ...this.flatFilterConfiguration,
      ...this.chartOptions,
    };
  }

  get tableColumns(): TableColumnSelectorConfig {
    return {
      changePercent: !!this.chartOptions.show_percentual_change,
      absoluteChange: !!this.chartOptions.show_absolute_change,
      changeIndex: !!this.chartOptions.show_index,
      proportions: !!this.chartOptions.proportions,
    };
  }

  get sort(): string {
    return _.first(_.get(this.filters, "sort", ["balanced"]));
  }

  get sortDirection() {
    return /^[\-=]/.test(this.sort) ? this.sort[0] : "";
  }

  get sortKey(): string {
    return /^[\-=+]/.test(this.sort) ? this.sort.substring(1) : this.sort;
  }

  get chartVariant() {
    return this.chartOptions.chart_variant || "value";
  }

  updateSortDirection(sortDirection) {
    this.updateSort(`${sortDirection}${this.sortKey}`);
  }

  updateSortKey(sortKey) {
    this.updateSort(`${this.sortDirection}${sortKey}`);
  }

  updateSort(sort) {
    this.updateFilterConfiguration({
      sort: {
        [sort]: { enabled: true },
      },
    });
  }

  updateChartVariant(chartVariant) {
    this.updateChartOptions({ chart_variant: chartVariant });
  }

  updateDataLabels(data_labels) {
    this.updateChartOptions({ data_labels });
  }

  updateProportions(proportions) {
    this.updateFilterConfiguration({
      proportions,
    });
  }

  updateRoundValues(round_values) {
    this.updateFilterConfiguration({
      round_values,
    });
  }

  updateChartOrientation(orientation) {
    this.updateChartOptions({
      inverted: orientation == "bar",
    });
  }

  updateTableColumns(tableColumns) {
    this.updateChartOptions({
      show_percentual_change: !!tableColumns.changePercent,
      show_absolute_change: !!tableColumns.absoluteChange,
      show_index: !!tableColumns.changeIndex,
      proportions: !!tableColumns.proportions,
    });
  }

  updateFilterConfiguration(updates) {
    const filterConfiguration: FilterConfiguration = filterToFlyover({
      ...this.flatFilterConfiguration,
      ...updates,
    });
    this.$emit("update-filter-configuration", filterConfiguration);
  }

  updateChartOptions(updates) {
    const chartOptions = {
      ...this.chartOptions,
      ...updates,
    };
    this.$emit("update-chart-options", chartOptions);
  }

  updateTableConfig(tableConfig) {
    this.$emit("update-table-config", tableConfig);
  }
}
</script>

<style lang="scss" scoped>
.report-controls {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
</style>
